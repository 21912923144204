import React, { useEffect, useState } from "react"

import Layout from "../components/Layout"
import styled from "styled-components"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import notFoundGif from "../images/not-found.gif"

const StyledWrapper = styled.div`
  .align-center {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 15%;
  }

  .container {
    height: calc(100vh - 105px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .timer,
  .redirect {
    color: #464fd0;
    font-weight: 700;
  }

  .not-found-gif {
    width: 400px;
  }

  .page-not-found {
    padding-top: 200px;
    height: 100vh;
  }

  @media (min-width: 992px) {
    .page-not-found {
      transition: 750ms;
    }
  }

  .updated-text {
    max-width: 700px;
  }
`

const NotFoundPage = () => {
  const [timer, setTimer] = useState(5)

  useEffect(() => {
    window.setTimeout(() => {
      navigate("/")
    }, 5000)
  }, [])

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000)
    }
  }, [timer])

  return (
    <Layout>
      <StyledWrapper>
        <SEO title="404" />
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center mx-auto">
              <img
                className="img-fluid not-found-gif mx-auto"
                src={notFoundGif}
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mx-auto updated-text">
                <h1>We've recently upgraded our website</h1>
                <p>
                  This page has moved and we’re redirecting you to our homepage
                  in <span className="timer">{timer} seconds</span>
                </p>
                <p>
                  If you're not redirected,{" "}
                  <a className="redirect" href="/">
                    please click here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </StyledWrapper>
    </Layout>
  )
}

export default NotFoundPage
